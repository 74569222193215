import { Outlet } from '@remix-run/react';

import { OrgFeatureProvider } from '../components/Organization';
import { useOrgContext } from '../components/Organization/Details';

export function OrganizationAnalyticsRoute() {
  const { org } = useOrgContext();
  return (
    <OrgFeatureProvider
      org={org}
      routePrefix={`/admin/organizations/${org.id}`}
      adminMode
    >
      <Outlet />
    </OrgFeatureProvider>
  );
}

export const Component = OrganizationAnalyticsRoute;

export function clientLoader() {
  return null;
}
